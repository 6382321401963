<template>
  <div :dir="localDisplayLang === 'ara' ? 'rtl' : 'ltr'">
    <overlay v-if="!paymentDetail"></overlay>
    <payment :paymentDetail="paymentDetail" :closePopup="() => closePopup()" v-else> </payment>
  </div>
</template>

<script>
import { eventBus } from "@/eventBus";
import snackbar from "node-snackbar";
import "../../../node_modules/node-snackbar/dist/snackbar.css";

import { showSnackBar } from '@/utilities';

export default {
  data() {
    return {
      width: "50%",
      margin: "10% auto",
      radiusType: "full",
      paymentDetail: null,
      localDisplayLang: null,
      planid: null,
      planSelected: null,
      gatewayResponse: [],
    };
  },
  created() {
    this.localDisplayLang = localStorage.getItem("setDisplayLanguageCode");
    this.planid = this.$route.params.planid;
    this.listPlans();
  },
  mounted() {
    //event for fetching available plans for given availability id.
    eventBus.$on("planlist-subscriptions-response", (response) => {
      console.log("planlist response", response);
      if (!response.reason) {
        let index = response.data.findIndex((element) => {
          return element.planid === this.planid;
        });

        if (index > -1) {
          this.planSelected = response.data[index];
          this.listGateways();
        } else {
          //error handling
          // snackbar.show({
          //   text: "Plan Unavailable",
          //   textColor: "#ffffff",
          //   pos: "bottom-center",
          //   actionTextColor: "#de2600",
          // });
          showSnackBar(this.$t("Plan Unavailable"));

          this.closePopup();
        }
      } else {
        //error handling
        // snackbar.show({
        //   text: response.reason,
        //   textColor: "#ffffff",
        //   pos: "bottom-center",
        //   actionTextColor: "#de2600",
        // });

        showSnackBar(this.$t(response.reason));

        this.closePopup();
      }
    });

    eventBus.$on("listgateway-response", (response) => {
      if (!response.data.reason) {
        this.gatewayResponse = response.data;
        this.constructPaymentDetail();
      } else {
        //error handling
        // snackbar.show({
        //   text: response.data.reason,
        //   textColor: "#ffffff",
        //   pos: "bottom-center",
        //   actionTextColor: "#de2600",
        // });

        showSnackBar(this.$t(response.data.reason));

        this.closePopup();
      }
    });
  },
  methods: {
    closePopup() {
      this.$router.go(-1);
    },
    listPlans() {
      let payload = {
        planvisibility: "YES",
        // descriptiontest: "STC",
        displaylanguage: this.localDisplayLang,
      };
      eventBus.$emit("planList_subscriptions", payload);
    },
    listGateways() {
      eventBus.$emit("listGateway");
    },
    constructPaymentDetail() {
      let payload = {
        state: true,
        detail: this.planSelected,
        gateways: this.gatewayResponse,
        transactionPurpose: "SUBSCRIPTION",
      };

      this.paymentDetail = payload;
    },
  },
  components: {
    payment: () => import(/* webpackChunkName: "payment" */ "@/components/Popups/Payment/Payment.vue"),
    overlay: () => import(/* webpackChunkName: "overlayLight" */ "@/components/Templates/overlayLoader.vue"),
  },
  beforeDestroy() {
    eventBus.$off("planlist-subscriptions-response");
    eventBus.$off("listgateway-response");
  },
};
</script>

<style></style>
